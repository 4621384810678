const AwardSvg2: React.FC = () => {
  return (
    <svg
      xmlnsXlink="http://www.w3.org/1999/xlink"
      xmlns="http://www.w3.org/2000/svg"
      data-v-2d0f0f8f=""
      width="130"
      height="114"
      viewBox="0 0 130 114"
    >
      <defs>
        <symbol id="02-AWWWARD" viewBox="0 0 130 114">
          <g>
            <circle cx="94.34" cy="72.46" r="7.01"></circle>
            <polygon points="79.45 34.53 72.44 66.13 64.28 34.53 59.42 34.53 54.55 34.53 46.39 66.13 39.38 34.53 28.65 34.53 42.39 79.47 51.55 79.47 59.42 50.27 67.29 79.47 76.45 79.47 90.19 34.53 79.45 34.53"></polygon>
          </g>
        </symbol>
      </defs>
      <g>
        <g>
          <circle cx="94.34" cy="72.46" r="7.01"></circle>
          <polygon points="79.45 34.53 72.44 66.13 64.28 34.53 59.42 34.53 54.55 34.53 46.39 66.13 39.38 34.53 28.65 34.53 42.39 79.47 51.55 79.47 59.42 50.27 67.29 79.47 76.45 79.47 90.19 34.53 79.45 34.53"></polygon>
        </g>
      </g>
    </svg>
  );
};

export default AwardSvg2;
