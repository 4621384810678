const AwardSvg6: React.FC = () => {
    return (
        <svg width="100" height="45" viewBox="0 0 129 76" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M129.001 75.2018C104.715 75.2018 80.5523 75.2018 56.1133 75.2018C63.9939 50.237 71.8438 25.303 79.7244 0.399683C79.8471 0.399683 80.0004 0.399682 80.123 0.368937C85.3052 16.7867 90.4874 33.1736 95.6696 49.5914C95.7309 49.5914 95.8229 49.5606 95.8843 49.5606C95.455 47.4085 95.0257 45.2256 94.5657 43.0735C94.2284 41.5362 93.7991 39.999 93.5845 38.4618C93.4618 37.6316 93.5845 36.7093 93.8298 35.9099C97.4788 24.2269 101.189 12.5131 104.869 0.830109C104.93 0.64564 105.083 0.491917 105.359 0C113.271 25.18 121.121 50.1448 129.001 75.2018Z" fill="black"/>
        <path d="M0 75.2019C0 49.9022 0 25.611 0 0.586253C0.552924 0.586253 1.07513 0.586253 1.62805 0.586253C12.3486 0.586253 23.0999 0.555695 33.8205 0.616806C37.1688 0.647361 40.6092 0.616808 43.896 1.25847C55.3538 3.45844 62.0196 16.1388 57.6577 26.8332C56.8283 28.8804 55.2617 30.1942 53.695 31.5387C38.3975 44.4941 23.0999 57.4495 7.80237 70.4354C6.45078 71.5353 6.29719 71.6881 3.53257 73.3686C2.30385 74.1325 1.5359 74.4381 0 75.2019Z" fill="black"/>
        <path d="M2.89453 75.1597C4.86464 73.4595 7.09528 71.6683 8.91147 70.1503C17.0382 63.3192 25.1649 56.4882 33.3223 49.6571C37.4165 46.2264 41.5106 42.7957 45.5739 39.3346C46.4051 38.6363 47.1131 38.606 48.0673 39.0614C55.7323 42.6742 60.2882 51.3876 58.6875 59.3724C56.902 68.2376 49.7604 74.8257 40.8949 75.0383C28.828 75.3115 16.7303 75.1597 4.63264 75.1597C4.20401 75.1597 3.58835 75.1597 2.89453 75.1597Z" fill="black"/>
        </svg>        
    );
  };
  
  export default AwardSvg6;
  