const AwardSvg3: React.FC = () => {
  return (
    <svg
      xmlnsXlink="http://www.w3.org/1999/xlink"
      xmlns="http://www.w3.org/2000/svg"
      data-v-2d0f0f8f=""
      width="130"
      height="114"
      viewBox="0 0 130 114"
    >
      <defs>
        <symbol id="05-WOLDA" viewBox="0 0 130 114">
          <g>
            <g>
              <path d="M62,40.81H75.29A1.93,1.93,0,0,1,77.47,43V47.8c0,1.24-.51,1.73-1.75,1.74a3.87,3.87,0,0,1-.59,0,2.68,2.68,0,0,0-2.84,1.3c-2,2.71-4.21,5.32-6.31,8a1.5,1.5,0,0,0-.26.74c-.19,2.71-.35,5.43-.53,8.15-.06.92-.14,1.83-.18,2.75s-.51,1.6-1.51,1.64c-1.25,0-2.51,0-3.77,0a1.57,1.57,0,0,1-1.55-1.64c-.13-2.15-.29-4.3-.44-6.45-.06-.8-.1-1.6-.14-2.41a18.27,18.27,0,0,0-.15-2.34,2.77,2.77,0,0,0-.66-1C53.52,54,50.23,49.86,47,45.67a2.8,2.8,0,0,1-.44-1.29,7.37,7.37,0,0,1,0-1.41,2,2,0,0,1,2.2-2.16H62Zm-.38,2.81c-3.69,0-7.38,0-11.08,0-.29,0-.78.16-.83.33A1.37,1.37,0,0,0,50,45c.88,1.17,1.82,2.3,2.73,3.45,2.12,2.69,4.25,5.37,6.35,8.08a4,4,0,0,1,1.22,2.21c.09,2.16.23,4.31.36,6.46.07,1.23.14,2.47.25,3.7,0,.12.19.28.33.33a2.31,2.31,0,0,0,.59,0c.36,0,.53-.1.55-.48,0-.9.12-1.8.17-2.7.17-2.54.31-5.09.5-7.63a1.73,1.73,0,0,1,.35-.9C66.68,53.31,70,49.15,73.27,45a1.32,1.32,0,0,0,.26-.93c-.08-.46-.54-.42-.92-.42Z"></path>
              <path d="M38.44,41.52a15.07,15.07,0,0,1-.13-5.75l0-.11c.29-.94.86-1.44,1.48-1.29a1.88,1.88,0,0,1,1.32,2.06,9.69,9.69,0,0,1-2,4.11C38.94,40.88,38.68,41.19,38.44,41.52Z"></path>
              <path d="M63.25,76.73a14.66,14.66,0,0,1,5.09-.19,4.71,4.71,0,0,1,1.41.53,1.54,1.54,0,0,1,.59,1.85,1.3,1.3,0,0,1-1.55.73,12,12,0,0,1-3.9-1.8C64.36,77.54,63.87,77.16,63.25,76.73Z"></path>
              <path d="M85.35,41.62c-.18-.24-.37-.47-.53-.72-.66-1.1-1.35-2.19-1.93-3.33a2.71,2.71,0,0,1-.19-2.15,1.37,1.37,0,0,1,1.24-1c.71,0,1.06.14,1.27.84a8,8,0,0,1,.39,2.11c0,1.39,0,2.79-.09,4.19Z"></path>
              <path d="M86.44,63.28c.52-1.83,1-3.68,2.23-5.16a1.89,1.89,0,0,1,1.87-.7,1.21,1.21,0,0,1,.94,1.72A5.07,5.07,0,0,1,89.79,61a14,14,0,0,1-1.66,1.2C87.58,62.61,87,62.94,86.44,63.28Z"></path>
              <path d="M40.62,63.89c0,.8-.08,1.6-.13,2.4a1.35,1.35,0,0,1-.11.32,1,1,0,0,1-.26-.19,13.26,13.26,0,0,1-2.37-3.93,4.91,4.91,0,0,1-.16-1.71,1.43,1.43,0,0,1,1.23-1.24,1,1,0,0,1,1.31.9c.3,1.12.42,2.28.61,3.43Z"></path>
              <path d="M53,76.76c-1.16.22-2.31.51-3.48.66a14.88,14.88,0,0,1-2.67,0,1.05,1.05,0,0,1-1.07-.91,1.45,1.45,0,0,1,.7-1.54,2.77,2.77,0,0,1,2.65-.09c1.32.47,2.6,1.08,3.89,1.64Z"></path>
              <path d="M70.56,76.73A13.15,13.15,0,0,1,76,74.64a1.63,1.63,0,0,1,1.87,2.08c-.14.51-.68.8-1.52.84A15.93,15.93,0,0,1,72,77Z"></path>
              <path d="M35.07,55.52A15.55,15.55,0,0,1,31.9,50.6a1.82,1.82,0,0,1-.1-.45c-.11-1,0-1.22.67-1.47a1.54,1.54,0,0,1,1.89.62A5.14,5.14,0,0,1,35,51.14c.13,1.41.1,2.84.13,4.27C35.17,55.44,35.12,55.46,35.07,55.52Z"></path>
              <path d="M78.46,71.31c.39-1.74.75-3.48,1.18-5.21A3.1,3.1,0,0,1,80.3,65a1.14,1.14,0,0,1,1.59,0,1.7,1.7,0,0,1,.57,1.93A9.58,9.58,0,0,1,80,70.22c-.41.43-.88.8-1.32,1.2Z"></path>
              <path d="M73.35,74.41c.75-1.78,1.22-3.69,2.53-5.2a2.5,2.5,0,0,1,1-.69,1.06,1.06,0,0,1,1.27.59,1.56,1.56,0,0,1,0,1.82,6.57,6.57,0,0,1-1.39,1.49c-1,.78-2.16,1.45-3.25,2.16Z"></path>
              <path d="M50.29,74.52c-1.69-1-3.47-1.78-4.64-3.45a1.76,1.76,0,0,1-.09-1.94,1.15,1.15,0,0,1,1.9-.33,6.77,6.77,0,0,1,1.24,1.68c.64,1.28,1.18,2.6,1.77,3.91Z"></path>
              <path d="M83.36,66.5a6,6,0,0,1-.13-.74,16.77,16.77,0,0,1,.2-5c.21-.9.66-1.29,1.31-1.27A1.67,1.67,0,0,1,86.22,61a4.65,4.65,0,0,1-.83,2.81c-.57.95-1.24,1.84-1.87,2.75Z"></path>
              <path d="M60.37,76.91c-1.31.75-2.61,1.53-3.94,2.24a4.56,4.56,0,0,1-1.5.44c-.87.12-1.39-.2-1.58-.84a1.57,1.57,0,0,1,.86-1.84A5,5,0,0,1,56,76.42c1.45,0,2.9.13,4.36.2Z"></path>
              <path d="M41.87,69.82c-1.25-.29-2.52-.53-3.75-.88a8.67,8.67,0,0,1-2-.93,1.23,1.23,0,0,1-.65-1.63,1.69,1.69,0,0,1,1.76-.86,4.59,4.59,0,0,1,1.43.63A12.78,12.78,0,0,1,41.87,69.82Z"></path>
              <path d="M40.88,39.17a13.53,13.53,0,0,1,3.92-4,2,2,0,0,1,1.74-.1,1.12,1.12,0,0,1,.87,1.05,1.68,1.68,0,0,1-.55,1.14,9.55,9.55,0,0,1-3.74,1.54c-.71.19-1.44.34-2.15.51Z"></path>
              <path d="M82.94,39.33a21.18,21.18,0,0,1-5.63-1.76c-.89-.48-1.18-1.07-.92-1.72a1.58,1.58,0,0,1,2-.86,5,5,0,0,1,1.54.9A16.19,16.19,0,0,1,82.94,39.33Z"></path>
              <path d="M87.74,52.18A17.06,17.06,0,0,1,84,47.43c-.27-.56-.26-1.43.33-1.72a1.68,1.68,0,0,1,2.12.39,7.32,7.32,0,0,1,1,2.28c.26,1.22.3,2.49.44,3.75Z"></path>
              <path d="M36.79,59.79c-.26-1.4-.57-2.8-.75-4.21a5,5,0,0,1,.18-2,1.5,1.5,0,0,1,2.06-1,1.08,1.08,0,0,1,.55.79,10.46,10.46,0,0,1-.31,2.75,37,37,0,0,1-1.46,3.59Z"></path>
              <path d="M88,48.16a14.88,14.88,0,0,1-1.33-4.55,5.94,5.94,0,0,1,.2-1.73,1.41,1.41,0,0,1,1.51-1,1,1,0,0,1,1,.74,4.59,4.59,0,0,1,.08,1.84c-.36,1.59-.84,3.14-1.27,4.71Z"></path>
              <path d="M45.16,71.36A11.23,11.23,0,0,1,41.35,67,1.72,1.72,0,0,1,42,64.77a1.11,1.11,0,0,1,1.64.48,16,16,0,0,1,1,2.86C44.89,69.17,45,70.27,45.16,71.36Z"></path>
              <path d="M47.26,74.13c-1,0-1.84.05-2.67,0A20.72,20.72,0,0,1,42,73.77a4.11,4.11,0,0,1-1.28-.54,1,1,0,0,1-.53-1.14,1.46,1.46,0,0,1,1.33-1.22,4.7,4.7,0,0,1,1.75.34A14.68,14.68,0,0,1,47.26,74.13Z"></path>
              <path d="M57,75.72c-1.9-.41-3.9-.48-5.56-1.7a1.77,1.77,0,0,1-.61-1.94A1,1,0,0,1,52,71.3a4.64,4.64,0,0,1,1.75.87c1.09,1,2.07,2.08,3.09,3.13a1.41,1.41,0,0,1,.18.29Z"></path>
              <path d="M37.34,63.12a15.88,15.88,0,0,1-4.45-3.06,4,4,0,0,1-.66-1,1.08,1.08,0,0,1,.64-1.56,2,2,0,0,1,2.3.66,10.47,10.47,0,0,1,1.67,3.38C37,62.09,37.17,62.61,37.34,63.12Z"></path>
              <path d="M35.9,52.05c.22-1.72.08-3.5.84-5.14a2,2,0,0,1,2-1.39,1.12,1.12,0,0,1,1.11,1.55,8.26,8.26,0,0,1-1.14,2.08c-.8,1-1.71,2-2.58,3Z"></path>
              <path d="M81.81,69.7a14.23,14.23,0,0,1,3.34-3.58c1.07-.78,1.75-.82,2.53-.36a1.25,1.25,0,0,1,.06,2.16A16.64,16.64,0,0,1,85,69.06c-1,.33-2.1.54-3.15.81Z"></path>
              <path d="M76.56,74.08c1.41-1.26,2.75-2.64,4.67-3.16a1.67,1.67,0,0,1,2.2.88A1,1,0,0,1,83,73.2a4.77,4.77,0,0,1-2.58.81c-1.26.09-2.53.14-3.8.21Z"></path>
              <path d="M86.9,59.74c-1-1.8-2-3.55-2.1-5.64,0-.9.29-1.38,1-1.5a1.58,1.58,0,0,1,1.74,1,4.58,4.58,0,0,1,.16,2.64C87.46,57.4,87.17,58.57,86.9,59.74Z"></path>
              <path d="M37.55,45.17c.52-1.13,1-2.27,1.59-3.37a3.9,3.9,0,0,1,2-1.94,1.42,1.42,0,0,1,1.73.44A1.3,1.3,0,0,1,42.74,42,10.14,10.14,0,0,1,41,43.47c-.93.6-1.92,1.11-2.89,1.65a2.51,2.51,0,0,1-.46.16Z"></path>
              <path d="M66.59,75.58c.89-.94,1.77-1.9,2.69-2.82a12.27,12.27,0,0,1,1.33-1.05c.08-.06.17-.1.25-.15.87-.45,1.6-.38,1.94.19A1.68,1.68,0,0,1,72.3,74a13,13,0,0,1-2.54,1.09c-1,.3-2,.45-3.05.66Z"></path>
              <path d="M35.69,48.15c-.66-1.85-1.53-3.66-1.43-5.71.05-.86.38-1.43,1-1.51A1.4,1.4,0,0,1,37,42.12a7,7,0,0,1,0,2.42c-.25,1.23-.7,2.42-1.06,3.62Z"></path>
              <path d="M86.13,45.32l-1-.5a15.36,15.36,0,0,1-3.93-2.58,1.59,1.59,0,0,1-.6-1.45c.11-.61.64-.88,1.23-1a1.48,1.48,0,0,1,1.44.44,14.49,14.49,0,0,1,1.55,2.11c.54.91,1,1.89,1.44,2.84Z"></path>
              <path d="M88.45,55.38c.13-1.64.21-3.27.4-4.9a2.11,2.11,0,0,1,1.05-1.67,1.39,1.39,0,0,1,1.65.07,1.2,1.2,0,0,1,.37,1.49A18.67,18.67,0,0,1,90.55,53c-.53.85-1.2,1.62-1.8,2.43Z"></path>
            </g>
          </g>
        </symbol>
      </defs>
      <g>
        <g>
          <g>
            <path d="M62,40.81H75.29A1.93,1.93,0,0,1,77.47,43V47.8c0,1.24-.51,1.73-1.75,1.74a3.87,3.87,0,0,1-.59,0,2.68,2.68,0,0,0-2.84,1.3c-2,2.71-4.21,5.32-6.31,8a1.5,1.5,0,0,0-.26.74c-.19,2.71-.35,5.43-.53,8.15-.06.92-.14,1.83-.18,2.75s-.51,1.6-1.51,1.64c-1.25,0-2.51,0-3.77,0a1.57,1.57,0,0,1-1.55-1.64c-.13-2.15-.29-4.3-.44-6.45-.06-.8-.1-1.6-.14-2.41a18.27,18.27,0,0,0-.15-2.34,2.77,2.77,0,0,0-.66-1C53.52,54,50.23,49.86,47,45.67a2.8,2.8,0,0,1-.44-1.29,7.37,7.37,0,0,1,0-1.41,2,2,0,0,1,2.2-2.16H62Zm-.38,2.81c-3.69,0-7.38,0-11.08,0-.29,0-.78.16-.83.33A1.37,1.37,0,0,0,50,45c.88,1.17,1.82,2.3,2.73,3.45,2.12,2.69,4.25,5.37,6.35,8.08a4,4,0,0,1,1.22,2.21c.09,2.16.23,4.31.36,6.46.07,1.23.14,2.47.25,3.7,0,.12.19.28.33.33a2.31,2.31,0,0,0,.59,0c.36,0,.53-.1.55-.48,0-.9.12-1.8.17-2.7.17-2.54.31-5.09.5-7.63a1.73,1.73,0,0,1,.35-.9C66.68,53.31,70,49.15,73.27,45a1.32,1.32,0,0,0,.26-.93c-.08-.46-.54-.42-.92-.42Z"></path>
            <path d="M38.44,41.52a15.07,15.07,0,0,1-.13-5.75l0-.11c.29-.94.86-1.44,1.48-1.29a1.88,1.88,0,0,1,1.32,2.06,9.69,9.69,0,0,1-2,4.11C38.94,40.88,38.68,41.19,38.44,41.52Z"></path>
            <path d="M63.25,76.73a14.66,14.66,0,0,1,5.09-.19,4.71,4.71,0,0,1,1.41.53,1.54,1.54,0,0,1,.59,1.85,1.3,1.3,0,0,1-1.55.73,12,12,0,0,1-3.9-1.8C64.36,77.54,63.87,77.16,63.25,76.73Z"></path>
            <path d="M85.35,41.62c-.18-.24-.37-.47-.53-.72-.66-1.1-1.35-2.19-1.93-3.33a2.71,2.71,0,0,1-.19-2.15,1.37,1.37,0,0,1,1.24-1c.71,0,1.06.14,1.27.84a8,8,0,0,1,.39,2.11c0,1.39,0,2.79-.09,4.19Z"></path>
            <path d="M86.44,63.28c.52-1.83,1-3.68,2.23-5.16a1.89,1.89,0,0,1,1.87-.7,1.21,1.21,0,0,1,.94,1.72A5.07,5.07,0,0,1,89.79,61a14,14,0,0,1-1.66,1.2C87.58,62.61,87,62.94,86.44,63.28Z"></path>
            <path d="M40.62,63.89c0,.8-.08,1.6-.13,2.4a1.35,1.35,0,0,1-.11.32,1,1,0,0,1-.26-.19,13.26,13.26,0,0,1-2.37-3.93,4.91,4.91,0,0,1-.16-1.71,1.43,1.43,0,0,1,1.23-1.24,1,1,0,0,1,1.31.9c.3,1.12.42,2.28.61,3.43Z"></path>
            <path d="M53,76.76c-1.16.22-2.31.51-3.48.66a14.88,14.88,0,0,1-2.67,0,1.05,1.05,0,0,1-1.07-.91,1.45,1.45,0,0,1,.7-1.54,2.77,2.77,0,0,1,2.65-.09c1.32.47,2.6,1.08,3.89,1.64Z"></path>
            <path d="M70.56,76.73A13.15,13.15,0,0,1,76,74.64a1.63,1.63,0,0,1,1.87,2.08c-.14.51-.68.8-1.52.84A15.93,15.93,0,0,1,72,77Z"></path>
            <path d="M35.07,55.52A15.55,15.55,0,0,1,31.9,50.6a1.82,1.82,0,0,1-.1-.45c-.11-1,0-1.22.67-1.47a1.54,1.54,0,0,1,1.89.62A5.14,5.14,0,0,1,35,51.14c.13,1.41.1,2.84.13,4.27C35.17,55.44,35.12,55.46,35.07,55.52Z"></path>
            <path d="M78.46,71.31c.39-1.74.75-3.48,1.18-5.21A3.1,3.1,0,0,1,80.3,65a1.14,1.14,0,0,1,1.59,0,1.7,1.7,0,0,1,.57,1.93A9.58,9.58,0,0,1,80,70.22c-.41.43-.88.8-1.32,1.2Z"></path>
            <path d="M73.35,74.41c.75-1.78,1.22-3.69,2.53-5.2a2.5,2.5,0,0,1,1-.69,1.06,1.06,0,0,1,1.27.59,1.56,1.56,0,0,1,0,1.82,6.57,6.57,0,0,1-1.39,1.49c-1,.78-2.16,1.45-3.25,2.16Z"></path>
            <path d="M50.29,74.52c-1.69-1-3.47-1.78-4.64-3.45a1.76,1.76,0,0,1-.09-1.94,1.15,1.15,0,0,1,1.9-.33,6.77,6.77,0,0,1,1.24,1.68c.64,1.28,1.18,2.6,1.77,3.91Z"></path>
            <path d="M83.36,66.5a6,6,0,0,1-.13-.74,16.77,16.77,0,0,1,.2-5c.21-.9.66-1.29,1.31-1.27A1.67,1.67,0,0,1,86.22,61a4.65,4.65,0,0,1-.83,2.81c-.57.95-1.24,1.84-1.87,2.75Z"></path>
            <path d="M60.37,76.91c-1.31.75-2.61,1.53-3.94,2.24a4.56,4.56,0,0,1-1.5.44c-.87.12-1.39-.2-1.58-.84a1.57,1.57,0,0,1,.86-1.84A5,5,0,0,1,56,76.42c1.45,0,2.9.13,4.36.2Z"></path>
            <path d="M41.87,69.82c-1.25-.29-2.52-.53-3.75-.88a8.67,8.67,0,0,1-2-.93,1.23,1.23,0,0,1-.65-1.63,1.69,1.69,0,0,1,1.76-.86,4.59,4.59,0,0,1,1.43.63A12.78,12.78,0,0,1,41.87,69.82Z"></path>
            <path d="M40.88,39.17a13.53,13.53,0,0,1,3.92-4,2,2,0,0,1,1.74-.1,1.12,1.12,0,0,1,.87,1.05,1.68,1.68,0,0,1-.55,1.14,9.55,9.55,0,0,1-3.74,1.54c-.71.19-1.44.34-2.15.51Z"></path>
            <path d="M82.94,39.33a21.18,21.18,0,0,1-5.63-1.76c-.89-.48-1.18-1.07-.92-1.72a1.58,1.58,0,0,1,2-.86,5,5,0,0,1,1.54.9A16.19,16.19,0,0,1,82.94,39.33Z"></path>
            <path d="M87.74,52.18A17.06,17.06,0,0,1,84,47.43c-.27-.56-.26-1.43.33-1.72a1.68,1.68,0,0,1,2.12.39,7.32,7.32,0,0,1,1,2.28c.26,1.22.3,2.49.44,3.75Z"></path>
            <path d="M36.79,59.79c-.26-1.4-.57-2.8-.75-4.21a5,5,0,0,1,.18-2,1.5,1.5,0,0,1,2.06-1,1.08,1.08,0,0,1,.55.79,10.46,10.46,0,0,1-.31,2.75,37,37,0,0,1-1.46,3.59Z"></path>
            <path d="M88,48.16a14.88,14.88,0,0,1-1.33-4.55,5.94,5.94,0,0,1,.2-1.73,1.41,1.41,0,0,1,1.51-1,1,1,0,0,1,1,.74,4.59,4.59,0,0,1,.08,1.84c-.36,1.59-.84,3.14-1.27,4.71Z"></path>
            <path d="M45.16,71.36A11.23,11.23,0,0,1,41.35,67,1.72,1.72,0,0,1,42,64.77a1.11,1.11,0,0,1,1.64.48,16,16,0,0,1,1,2.86C44.89,69.17,45,70.27,45.16,71.36Z"></path>
            <path d="M47.26,74.13c-1,0-1.84.05-2.67,0A20.72,20.72,0,0,1,42,73.77a4.11,4.11,0,0,1-1.28-.54,1,1,0,0,1-.53-1.14,1.46,1.46,0,0,1,1.33-1.22,4.7,4.7,0,0,1,1.75.34A14.68,14.68,0,0,1,47.26,74.13Z"></path>
            <path d="M57,75.72c-1.9-.41-3.9-.48-5.56-1.7a1.77,1.77,0,0,1-.61-1.94A1,1,0,0,1,52,71.3a4.64,4.64,0,0,1,1.75.87c1.09,1,2.07,2.08,3.09,3.13a1.41,1.41,0,0,1,.18.29Z"></path>
            <path d="M37.34,63.12a15.88,15.88,0,0,1-4.45-3.06,4,4,0,0,1-.66-1,1.08,1.08,0,0,1,.64-1.56,2,2,0,0,1,2.3.66,10.47,10.47,0,0,1,1.67,3.38C37,62.09,37.17,62.61,37.34,63.12Z"></path>
            <path d="M35.9,52.05c.22-1.72.08-3.5.84-5.14a2,2,0,0,1,2-1.39,1.12,1.12,0,0,1,1.11,1.55,8.26,8.26,0,0,1-1.14,2.08c-.8,1-1.71,2-2.58,3Z"></path>
            <path d="M81.81,69.7a14.23,14.23,0,0,1,3.34-3.58c1.07-.78,1.75-.82,2.53-.36a1.25,1.25,0,0,1,.06,2.16A16.64,16.64,0,0,1,85,69.06c-1,.33-2.1.54-3.15.81Z"></path>
            <path d="M76.56,74.08c1.41-1.26,2.75-2.64,4.67-3.16a1.67,1.67,0,0,1,2.2.88A1,1,0,0,1,83,73.2a4.77,4.77,0,0,1-2.58.81c-1.26.09-2.53.14-3.8.21Z"></path>
            <path d="M86.9,59.74c-1-1.8-2-3.55-2.1-5.64,0-.9.29-1.38,1-1.5a1.58,1.58,0,0,1,1.74,1,4.58,4.58,0,0,1,.16,2.64C87.46,57.4,87.17,58.57,86.9,59.74Z"></path>
            <path d="M37.55,45.17c.52-1.13,1-2.27,1.59-3.37a3.9,3.9,0,0,1,2-1.94,1.42,1.42,0,0,1,1.73.44A1.3,1.3,0,0,1,42.74,42,10.14,10.14,0,0,1,41,43.47c-.93.6-1.92,1.11-2.89,1.65a2.51,2.51,0,0,1-.46.16Z"></path>
            <path d="M66.59,75.58c.89-.94,1.77-1.9,2.69-2.82a12.27,12.27,0,0,1,1.33-1.05c.08-.06.17-.1.25-.15.87-.45,1.6-.38,1.94.19A1.68,1.68,0,0,1,72.3,74a13,13,0,0,1-2.54,1.09c-1,.3-2,.45-3.05.66Z"></path>
            <path d="M35.69,48.15c-.66-1.85-1.53-3.66-1.43-5.71.05-.86.38-1.43,1-1.51A1.4,1.4,0,0,1,37,42.12a7,7,0,0,1,0,2.42c-.25,1.23-.7,2.42-1.06,3.62Z"></path>
            <path d="M86.13,45.32l-1-.5a15.36,15.36,0,0,1-3.93-2.58,1.59,1.59,0,0,1-.6-1.45c.11-.61.64-.88,1.23-1a1.48,1.48,0,0,1,1.44.44,14.49,14.49,0,0,1,1.55,2.11c.54.91,1,1.89,1.44,2.84Z"></path>
            <path d="M88.45,55.38c.13-1.64.21-3.27.4-4.9a2.11,2.11,0,0,1,1.05-1.67,1.39,1.39,0,0,1,1.65.07,1.2,1.2,0,0,1,.37,1.49A18.67,18.67,0,0,1,90.55,53c-.53.85-1.2,1.62-1.8,2.43Z"></path>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default AwardSvg3;
