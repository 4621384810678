const AwardSvg1: React.FC = () => {
  return (
    <svg
      xmlnsXlink="http://www.w3.org/1999/xlink"
      xmlns="http://www.w3.org/2000/svg"
      data-v-2d0f0f8f=""
      width="130"
      height="114"
      viewBox="0 0 130 114"
    >
      <defs>
        <symbol id="01-CSS" viewBox="0 0 130 114">
          <polygon points="71.22 64.74 55.72 49.24 71.19 34.38 55.35 34.38 41.1 49.24 63.84 72.12 71.22 64.74"></polygon>
          <polygon points="66.16 41.88 58.78 49.26 74.28 64.76 58.81 79.62 74.65 79.62 88.9 64.76 66.16 41.88"></polygon>
        </symbol>
      </defs>
      <g>
        <polygon points="71.22 64.74 55.72 49.24 71.19 34.38 55.35 34.38 41.1 49.24 63.84 72.12 71.22 64.74"></polygon>
        <polygon points="66.16 41.88 58.78 49.26 74.28 64.76 58.81 79.62 74.65 79.62 88.9 64.76 66.16 41.88"></polygon>
      </g>
    </svg>
  );
};

export default AwardSvg1;
