const BrandH2 = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="65" height="64" fill="none">
      <path
        fill="#fff"
        d="M60 0h5v5h-5V0ZM58 10h6v5h-6v-5ZM45 1h8v7h-8V1ZM36 11h18v16H36V11Z"
      />
      <path
        fill="#fff"
        d="M27.656 35.614V44.5H43.58c-.87 2.906-2.772 5.422-5.369 7.102-2.635 1.725-5.953 2.586-9.954 2.583-5.408 0-9.756-1.565-13.045-4.695-3.29-3.13-4.93-7.1-4.922-11.908 0-4.855 1.616-8.872 4.847-12.05 3.23-3.18 7.348-4.77 12.353-4.773a19.82 19.82 0 0 1 4.135.43v-9.923a31.238 31.238 0 0 0-4.06-.265c-7.811 0-14.358 2.573-19.639 7.72C2.645 23.864.003 30.152 0 37.58c0 7.382 2.667 13.634 8.001 18.754C13.335 61.455 20.056 64.01 28.162 64c7.614 0 13.823-2.258 18.627-6.773 4.805-4.515 7.208-10.413 7.211-17.696v-3.917H27.656Z"
      />
    </svg>
  );
};

export default BrandH2;
