const BrandH4 = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="139"
      height="31"
      viewBox="0 0 139 31"
      fill="none"
    >
      <path
        fill="#fff"
        d="m135.935 0-7.593 16.243-2.856-6.154h-3.088l4.696 10.067h2.496L139.001 0h-3.066ZM103.362 30.16h6.112V18.97c0-2.559 1.481-4.357 3.68-4.357 2.115 0 3.215 1.48 3.215 3.617V30.16h6.112V17.068c0-4.442-2.665-7.572-6.979-7.572-2.75 0-4.674 1.184-6.113 3.342h-.127v-2.75h-5.922l.022 20.072ZM91.348 9.538c-6.387 0-10.66 4.59-10.66 10.639 0 6.007 4.273 10.639 10.66 10.639 6.388 0 10.66-4.632 10.66-10.639.021-6.049-4.272-10.639-10.66-10.639Zm-.042 16.751c-2.898 0-4.442-2.432-4.442-6.112 0-3.723 1.523-6.112 4.442-6.112 2.898 0 4.505 2.39 4.505 6.112.021 3.68-1.586 6.112-4.505 6.112ZM63.26 30.16h17.174v-4.821h-9.73v-.127l9.307-10.427v-4.717H63.26v4.844h9.411v.126L63.261 25.55v4.61Zm-7.847 0h6.155V10.09h-6.155v20.07Zm-14.277 0h6.134v-9.22c0-4.188 2.517-6.05 6.345-5.52h.127V10.13c-.317-.127-.677-.148-1.248-.148-2.39 0-3.997 1.1-5.372 3.447h-.127v-3.342h-5.859V30.16Zm-11.252-3.848c-2.707 0-4.357-1.756-4.674-4.463h14.467c.042-4.315-1.1-7.762-3.49-9.92-1.692-1.565-3.913-2.432-6.747-2.432-6.049 0-10.236 4.59-10.236 10.596 0 6.05 3.997 10.66 10.638 10.66 2.517 0 4.505-.677 6.155-1.798 1.755-1.205 3.024-2.982 3.363-4.737h-5.88c-.571 1.311-1.798 2.094-3.596 2.094Zm-.317-12.437c2.157 0 3.638 1.608 3.892 3.955h-8.207c.487-2.369 1.777-3.955 4.315-3.955ZM6.98 30.16h6.43l6.979-20.072h-6.155L10.321 22.99h-.084L6.324 10.088H0L6.98 30.16ZM55.414 2.94h6.155v5.456h-6.155V2.94Z"
      />
    </svg>
  );
};

export default BrandH4;
